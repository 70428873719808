import { render, staticRenderFns } from "./machinations.vue?vue&type=template&id=2a887a0b"
import script from "./machinations.vue?vue&type=script&lang=ts"
export * from "./machinations.vue?vue&type=script&lang=ts"
import style0 from "./machinations.vue?vue&type=style&index=0&id=2a887a0b&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TrackingNoscript: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/_trackingNoscript.vue').default,HomepageHeader: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/homepage/HomepageHeader.vue').default,PageFooter: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/page/PageFooter.vue').default})
